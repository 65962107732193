<template>
  <div class="order-list">

    <section class="table-search">
      <div class="search-L">
        <div style="width: 350px" class="mr15 mb15">
          <el-input v-model="tableParams.kw" placeholder="输入用户手机号/卡券名称/兑换码/卡券id搜索" @input="kwChange" clearable />
        </div>

        <div style="width: 200px" class="mr15 mb15">
          <el-select v-model="tableParams.paymentType" placeholder="请选择卡券获取方式" @change="reload" clearable>
            <el-option v-for="item in ObjToOpt(platePaymentTypeObj)" :key="item.value" :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div style="width: 200px" class="mr15 mb15">
          <el-select v-model="tableParams.cardStatus" placeholder="请选择卡券订单状态" @change="reload" clearable>
            <el-option v-for="item in ObjToOpt(cardOrderStatusObj)" :key="item.value" :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div style="width: 200px" class="mr15 mb15">
          <el-select v-model="tableParams.cardType" placeholder="请选择卡券类型" @change="reload" clearable>
            <el-option v-for="item in ObjToOpt(plateCardTypeObj)" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <el-date-picker v-model="dateToDate" type="daterange" align="center" unlink-panels range-separator="~"
          start-placeholder="获得时间" end-placeholder="获得时间" :picker-options="pickerOptions" @change="reload" clearable
          value-format="yyyy-MM-dd">
        </el-date-picker>
      </div>
    </section>
    <!-- 表格 -->
    <el-table :data="tableData" border stripe :header-cell-style="{
            background: '#FAFAFA',
            color: '#000000',
          }">
      <el-table-column label="获得时间" align="center" width="100">
        <template slot-scope="scope">
          <span>{{ scope.row.addDt || "-" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="用户信息" align="center" min-width="180">
        <template slot-scope="scope">
          <span>{{ scope.row.userName || "-" }}</span>
          <span>&nbsp;|&nbsp;</span>
          <span>{{ scope.row.mobile || "-" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="卡券名称" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.cardName || "-" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="适用范围" align="center" min-width="280" prop="roomLimit">
      </el-table-column>
      <el-table-column label="可用日期" min-width="150">
        <template slot-scope="scope">
          <p v-for="(item, index) in scope.row.cardSuit" :key="index">
            <span>*该卡券{{ item.suitWriting }}，</span>
            <span v-if="item.suitStartTime == '00:00:00' &&
            item.suitEndTime == '00:00:00'
            ">全天可用</span>
            <span v-else>{{ item.suitStartTime }}-{{ item.suitEndTime }}可用</span>
            <span v-if="item.continuousMaxHour &&
            item.continuousMaxHour != 0 &&
            item.continuousMaxHour != 24
            ">{{ item.continuousMaxHour }}小时</span>
          </p>
        </template>
      </el-table-column>
      <el-table-column label="获得方式" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.paymentTypeName || "-" }}</span>
        </template>
      </el-table-column>
      <!-- <el-table-column label="团购兑换码" align="center">
          <template slot-scope="scope">
            <span>{{ scope.row.redemptionCode || "-" }}</span>
          </template>
        </el-table-column> -->
      <el-table-column label="卡券id" align="center" prop="memberCardId">
      </el-table-column>
      <el-table-column label="卡券剩余" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.residue || "-" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="有效期" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.validDate || "-" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="状态" align="center">
        <template slot-scope="scope">
          <span :style="{ color: cardOrderStatusColor[scope.row.cardStatus] }">{{ scope.row.cardStatusName || "-"
            }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button type="text" size="medium" v-if="scope.row.cardStatus == 0 && scope.row.cardType == 5"
            @click.stop="writeOff(scope.row)">核销</el-button>
          <el-button type="text" size="medium" v-if="scope.row.cardStatus != 0 && scope.row.cardType == 5"
            @click.stop="writeDetail(scope.row)">核销详情</el-button>

        </template>
      </el-table-column>
    </el-table>
    <!-- 分页 -->
    <section class="table-footer">
      <el-pagination layout="total,sizes, prev, pager, next,jumper" background :total="total"
        :page-size="tableParams.size" @size-change="onSizeChange" :page-sizes="[10, 20, 50, 100]"
        :current-page.sync="currentPage" @current-change="onCurrentPage">
      </el-pagination>
    </section>
    <el-dialog title="核销" :visible.sync="writeOffDialog" center width="60%">
      <el-form :model="writeform">
        <el-form-item label="核销门店" label-width="120px">
          <div>{{ writeform.shopName }}</div>
        </el-form-item>
        <el-form-item label="核销备注" label-width="120px">
          <el-input  type="textarea" v-model="writeform.remark" autocomplete="off" placeholder="请输入核销备注" maxlength="200" show-word-limit
           :rows="5"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="confirmWriteOff">确定核销</el-button>
      </div>
    </el-dialog>
    <el-dialog title="核销详情" :visible.sync="writeDetailDialog" center width="30%" @close="writeform.remark = ''">
      <el-form :model="writeform">
        <el-form-item label="核销时间" label-width="120px">
          <div>{{ writeform.addDt }}</div>
        </el-form-item>
        <el-form-item label="核销门店" label-width="120px">
          <div>{{ writeform.shopName }}</div>
        </el-form-item>

        <el-form-item label="核销备注" label-width="120px">
          <div>{{ writeform.remark }}</div>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import {
  getStopStartCard,

} from "@/api/card/order";
import { ObjToOpt,formatterDate } from "@/utils/utils";
import { merchantStatusObj } from "@/db/objs";


import {
  platePaymentTypeObj,
  cardOrderStatusObj,
  cardOrderStatusColor,
  plateCardTypeObj,
  cardTipsObj,
} from "@/db/objs";
import { getCardRoomList, getPlateformTableList,cardWriteOff,writeOffDetails } from "@/api/card/card";
export default {
  components: {

  },
  data() {
    return {
      formatterDate,
      ObjToOpt,
      merchantStatusObj,
      platePaymentTypeObj,
      cardOrderStatusObj,
      cardOrderStatusColor,
      plateCardTypeObj,
      cardTipsObj,
      dateToDate: [],
      roomList: [],
      cardNum: "",
      // table表格
      currentPage: 1, // 当前页
      total: 0, // 总条数
      tableData: [], //表格数据
      tableParams: {
        page: 1,
        size: 10,
        merchantId: "", //商户ID
        shopId: "", //店铺ID
        kw: "", //查询关键字
        serviceId: "", //店铺ID
        dateFrom: "", //店铺ID
        dateTo: "", //店铺ID
        paymentType: "", //店铺ID
        cardStatus: "", //店铺ID
        cardType: "", //店铺ID
      },
      writeOffDialog: false,
      writeDetailDialog: false,
      writeform: {},
      selectDate:'',
      pickerOptions:{
        onPick: ({ maxDate, minDate }) => {
          this.selectDate = minDate.getTime();
          if (maxDate) {
            this.selectDate = ''
          }
        },
        disabledDate: (time) => {
          if (this.selectDate !== '') {
            let d=new Date(this.selectDate)
            const minTime = d.setMonth(d.getMonth() - 1)
            const maxTime = d.setMonth(d.getMonth() + 2)
            console.log(minTime,maxTime)
            return time.getTime() < minTime || time.getTime() > maxTime
          }
        }
      }
    };
  },
  created() {
    let merchantShopId = window.localStorage.getItem(
      "playOne-store-merchantShopId"
    );
    if (merchantShopId) {
      this.tableParams.merchantId = JSON.parse(merchantShopId)[0];
      this.tableParams.shopId = this.writeform.shopId = JSON.parse(merchantShopId)[1];
    }
    let merchantShopInfo = window.localStorage.getItem(
      "playOne-store-merchantShopInfo"
    );
    if (merchantShopInfo) {
      this.writeform.shopName = JSON.parse(merchantShopInfo).shopName;
    }
  },
  mounted() {
    // this.initDate()

    this.getTableList(); // 【请求】表格数据
    this.getCardRoomList(); // 【请求】卡券房间列表
  },
  methods: {
    initDate(){
      let start=new Date().getTime()
      let d=new Date(start)
      d.setMonth(d.getMonth() - 1)
      this.tableParams.dateTo=this.formatterDate(start)
      this.tableParams.dateFrom=this.formatterDate(d)
      this.dateToDate=[this.formatterDate(d),this.formatterDate(start)]
    },

    // 【请求】表格数据
    getTableList() {
      if (this.dateToDate) {
        this.tableParams.dateFrom = this.dateToDate[0];
        this.tableParams.dateTo = this.dateToDate[1];
      } else {
        this.tableParams.dateFrom = "";
        this.tableParams.dateTo = "";
      }
      let data = this.tableParams;
      getPlateformTableList(data).then((res) => {
        if (res.isSuccess == "yes") {
          this.tableData = res.data.list;
          this.total = res.data.total;
        }
      });
    },

    // 【请求】卡券房间列表
    getCardRoomList() {
      let data = {
        merchantId: this.tableParams.merchantId,
        shopId: this.tableParams.shopId,
      };
      getCardRoomList(data).then((res) => {
        if (res.isSuccess == "yes") {
          this.roomList = res.data;
        }
      });
    },

    // 【请求】禁用或启用卡券
    getStopStartCard(data) {
      getStopStartCard(data).then((res) => {
        if (res.isSuccess == "yes") {
          this.$message({
            message: res.message,
            type: "success",
          });
          this.getTableList();
        }
      });
    },

    // 【监听】表格模糊查询
    kwChange: _.debounce(function () {
      this.reload();
    }, 500),

    // 【监听】表格重载
    reload() {
      this.tableParams.page = 1;
      this.currentPage = 1;
      this.getTableList();
    },

    // 【监听】表格分页点击
    onCurrentPage(page) {
      this.tableParams.page = page;
      this.getTableList();
    },

    // 【监听】表格条数点击
    onSizeChange(size) {
      this.tableParams.size = size;
      this.reload();
    },

    // 【监听】禁用卡券
    onStop(row) {
      console.log("row: ", row);
      this.$confirm(`你确定要禁用卡券吗?`, "禁用卡券", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "error",
      }).then(() => {
        let data = {
          memberCardId: row.memberCardId,
          isDisable: true,
          merchantId: this.tableParams.merchantId, //商户ID
          shopId: this.tableParams.shopId, //商户ID
        };
        this.getStopStartCard(data);
      });
    },

    // 【监听】取消禁用
    onStart(row) {
      console.log("row: ", row);
      this.$confirm(`你确定要取消禁用吗?`, "取消禁用", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "error",
      }).then(() => {
        let data = {
          memberCardId: row.memberCardId,
          isDisable: false,
          merchantId: this.tableParams.merchantId, //商户ID
          shopId: this.tableParams.shopId, //商户ID
        };
        this.getStopStartCard(data);
      });
    },

    // 核销
    writeOff(row) {
      this.writeOffDialog = true;
      this.writeform.memberCardId = row.memberCardId
      this.writeform.memberId = row.memberId
    },
    // 点击确认核销
    confirmWriteOff() {
      cardWriteOff(this.writeform).then(res => {
        if (res.isSuccess == 'yes') {
          this.$message({
            message: res.message,
            type: "success",
          });
          this.writeOffDialog = false;
          this.writeform.remark=''
          this.writeform.memberCardId = ''
          this.writeform.memberId = ''
          this.reload();
        }
      })
    },
    // 核销详情
    writeDetail(row) {
      this.writeDetailDialog = true;
      writeOffDetails({ writeOffId: row.writeOffId }).then(res => {
        this.writeform = res.data
      })
    },

    onSearch() {
      this.reload();
    },
  },
};
</script>

<style lang="scss" scoped>
.order-list {
  padding: 0.15rem;
}
/deep/.el-form-item__content{
  width:calc(100% - 120px);
}
.table-footer {
  margin-top: 0.1rem;
  display: flex;
  justify-content: flex-end;
}

.table-search {
  display: flex;
  margin-bottom: 0.15rem;
}

.search-L {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
</style>